<template>
  <div>
    <!-- 签约订单 -->
    <ch-layout page title="服务订单">
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  size="mini" height="100%">
          <template v-slot:handle="scope">
            <ch-button type="link"
                       @click="openWatch(scope.row)"
            >明细</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
          type="new"
          ref="page"
          :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>

    <modal ref="modal" :get-list-function="getTableList"></modal>
  </div>
</template>
<script>
import Modal from "./modal";
import dayjs from "dayjs";

export default {
  components: {Modal},
  data() {
    return {
      isTableLoading: false,
      tableList: [],
      treeSelect: {
        baseDetails: false, // 应用订单明细
      },
      statusObj:{ // 订单状态
        0:'未完成',
        1:'已完成',
        2:'待审核',
        3:'支付失败'
      }
    }
  },
  computed: {
    tableOption: function () {
      return [
        {column: 'type', prop: '', type: 'selection', width: '25px'},
        {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
        {column: 'text', label: '订单名称', prop: 'orderNo', showOverflowTooltip: true },
        {column: 'text', label: '项目名称', prop: 'itemName', showOverflowTooltip: true },
        {column: 'cash', label: '金额', prop: 'amount', showOverflowTooltip: true},
        {column: 'text', label: '支付单号', prop: 'transactionId', showOverflowTooltip: true},
        {column: 'text', label: '支付方式', prop: '_payType', showOverflowTooltip: true},
        {column: 'text', label: '订单状态', prop: '_status', showOverflowTooltip: true},
        {column: 'text', label: '创建时间', prop: 'createTime', showOverflowTooltip: true },
        {column: 'slot', label: '操作', slotName: 'handle', width: '60px', fixed: 'right'}
      ]
    },
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    // 列表数据
    getTableList() {
      this.isTableLoading = true
      this.$refs.page?.getServerList('/hm/serverorders/orders').then(res => {
        let rows = res.rows;
        rows.forEach((item) => {
          item._status = this.statusObj[item.status] || '一'
          item._payType = item.payWay== 0?'对公转账':item.payWay == 1?'现金支付':item.payWay==2?'微信支付':item.payWay==3?'支付宝支付':item.payWay==4?'余额支付':null
          item.createTime = item.createTime && dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')
        })
        this.tableList = rows;
        this.isTableLoading = false
      }).catch(() => {
        this.isTableLoading = false
      })
    },

    // 打开明细
    openWatch(row) {
      this.$refs.modal?.openWatch(row,'/hm/serverorders/orderDetail/')
    },
  }

}
</script>

<style lang="scss">

</style>
